import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { usePostHog } from "posthog-js/react";

const PostHogPageView = ({ locationId }) => {
  const { pathname, search } = useLocation();
  const posthog = usePostHog();

  const trackLocationView =
    pathname.includes("/location/") ||
    pathname.includes("/locations/") ||
    pathname.includes("/reviews/") ||
    pathname.includes("/listing/")

  useEffect(() => {
    if (pathname && posthog) {
      let url = window.origin + pathname
      if (search.toString()) {
        url = url + `?${search.toString()}`
      }

      if (trackLocationView) {
        if (!locationId) {
          return;
        }

        posthog.capture(
          "$pageview",
          {
            "$current_url": url,
            "location_id": locationId
          }
        )
        return;
      }

      posthog.capture(
        "$pageview",
        {
          "$current_url": url
        }
      )
    }
  }, [pathname, search, posthog, locationId])

  return null
}

export default PostHogPageView;