import React, { useState, useEffect, useCallback, lazy, Suspense } from "react";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { isMobile } from "react-device-detect";
import { Routes, Route, Outlet } from "react-router-dom";
import Modal from "react-modal";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import MainLayout from "./screens/layouts/MainLayout";
import MarketingLayout from "./screens/layouts/MarketingLayout";
import LandingPageLayout from "./screens/layouts/LandingPageLayout";
import BusinessLandingPageLayout from "./screens/layouts/BusinessLandingPageLayout";
import BusinessDashboardProvider from "./screens/BusinessDashboard/BusinessDashboardProvider";
import LocationProfileProvider from "./screens/LocationProfile/LocationProfileProvider";
import TiktokPixel from "tiktok-pixel";
import { globalClickHandler } from "./utils/globalTrackers";

// AtmosfyBiz
const BusinessLandingPage = lazy(() => import("./screens/Business"));
const ClaimBusinessPage = lazy(() => import("./screens/ClaimBusiness"));
const ClaimBusinessFormPage = lazy(() => import("./screens/ClaimBusinessForm"));
const ClaimBusinessCompletePage = lazy(() => import("./screens/ClaimBusinessForm/ClaimBusinessComplete"));
const BusinessDashboardSignIn = lazy(() => import("./screens/BusinessDashboard/AuthSignIn"));
const BusinessDashboard = lazy(() => import("./screens/BusinessDashboard"));

const CityProfile = lazy(() => import("./screens/CityProfile/CityProfile"));
const CreatorFundPage = lazy(() => import("./screens/CreatorFund"));
const CreatorsPage = lazy(() => import("./screens/Creators/Creators"));
const CreatorProfile = lazy(() => import("./screens/CreatorProfile/CreatorProfile"));
const KygoLandingPage = lazy(() => import("./screens/Kygo/KygoLandingPage"));
const KygoRulesPage = lazy(() => import("./screens/Kygo/KygoRulesPage"));
const LocationProfile = lazy(
  () => import("./screens/LocationProfile/LocationProfile")
);
const Marketing = lazy(() => import("./screens/Marketing/Marketing"));
const VeteranScholarship = lazy(
  () => import("./screens/VeteranScholarship/VeteranScholarship")
);
const VideoProfile = lazy(() => import("./screens/VideoProfile/VideoProfile"));
const Watch = lazy(() => import("./screens/Watch/Watch"));

const LANDING_PAGE_ROUTES = [
  "/scholarship",
  "/kygo-sweepstakes",
  "/kygo-sweepstakes-rules",
  "/creators",
  "/communityround"
];

const BUSINESS_PAGE_ROUTES = [
  "/biz",
  "/biz/claim-business",
  "/biz/sign-in",
  "/biz/dashboard",
  "/claim-business",
  "/claim-business/complete"
]

Modal.setAppElement("#root");

function App() {
  const [isMobileOverride, setIsMobileOverride] = useState(false);

  const determineMarketingModeEnabled = useCallback(() => {
    const pathSegmentLength = window.location.pathname
      .split("/")
      .filter((n) => !!n).length;

    return !isMobile && !isMobileOverride && pathSegmentLength === 0;
  }, [isMobileOverride]);

  const [isMarketingModeEnabled, setIsMarketingModeEnabled] = useState(
    determineMarketingModeEnabled()
  );

  const isLandingPage = LANDING_PAGE_ROUTES.includes(window.location.pathname);
  const isBusinessRoute =
    BUSINESS_PAGE_ROUTES.includes(window.location.pathname) || window.location.pathname.includes("biz");

  // Initialization function. Runs once on first render.
  useEffect(() => {
    const isMarketing = determineMarketingModeEnabled();
    setIsMarketingModeEnabled(isMarketing);

    // initialize google analytics
    ReactGA.initialize("G-EKN48SD54Q", { debug: false });

    // initialize facebook pixel
    ReactPixel.init("627640257698727", {}, { debug: false });

    TiktokPixel.init("CNB4J2BC77UC0L4MHTO0");
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname
    });
    ReactPixel.pageView();
    TiktokPixel.pageView();
    // call window resize on load
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);

    // Remove onscroll listener set on App.js
    window.onscroll = null;

    return () => {
      if (!isMarketingModeEnabled) {
        window.removeEventListener("resize", handleWindowSizeChange);
      }
    };
  }, [determineMarketingModeEnabled, isMarketingModeEnabled]);

  // TODO: move into a hook
  // handle window resize
  const handleWindowSizeChange = () => {
    if (window.innerWidth <= 768) {
      setIsMobileOverride(true);
    } else {
      setIsMobileOverride(false);
    }
  };

  if (isMarketingModeEnabled) {
    return (
      <div onClick={globalClickHandler}>
        <ErrorBoundary>
          <Routes>
            <Route
              path="*"
              element={
                <MarketingLayout isMobileOverride={isMobileOverride}>
                  <Marketing />
                </MarketingLayout>
              }
            />
          </Routes>
        </ErrorBoundary>
      </div>
    );
  }

  if (isBusinessRoute) {
    return (
      <div onClick={globalClickHandler}>
        <ErrorBoundary>
          <Routes>
            <Route
              path="*"
              element={
                <Suspense>
                  <BusinessLandingPageLayout>
                    <BusinessDashboardProvider>
                      <Outlet />
                    </BusinessDashboardProvider>
                  </BusinessLandingPageLayout>
                </Suspense>
              }
            >
              <Route path="biz" element={<BusinessLandingPage />} />
              <Route path="claim-business" element={<ClaimBusinessFormPage />} />
              <Route path="claim-business/complete" element={<ClaimBusinessCompletePage />} />
              <Route path="biz/claim-business" element={<ClaimBusinessPage />} />
              <Route path="biz/sign-in" element={<BusinessDashboardSignIn />} />
              <Route path="biz/dashboard" element={<BusinessDashboard />} />
              <Route path="biz/dashboard/overview" element={<BusinessDashboard />} />
              <Route path="biz/dashboard/info" element={<BusinessDashboard />} />
              <Route path="biz/dashboard/growth" element={<BusinessDashboard />} />
              <Route path="*" element={<BusinessLandingPage />} />
            </Route>
          </Routes>
        </ErrorBoundary>
      </div>
    );
  }

  if (isLandingPage) {
    return (
      <div onClick={globalClickHandler}>
        <ErrorBoundary>
          <Routes>
            <Route
              path="*"
              element={
                <LandingPageLayout>
                  <Outlet />
                </LandingPageLayout>
              }
            >
              <Route path="scholarship" element={<VeteranScholarship />} />
              <Route path="kygo-sweepstakes" element={<KygoLandingPage />} />
              <Route path="kygo-sweepstakes-rules" element={<KygoRulesPage />} />
              <Route path="creators" element={<CreatorsPage />} />
              <Route path="communityround" element={<CreatorFundPage />} />
            </Route>
          </Routes>
        </ErrorBoundary>
      </div>
    );
  }

  return (
    <div onClick={globalClickHandler}>
      <ErrorBoundary>
        <Routes>
          <Route
            element={
              <MainLayout isMobileOverride={isMobileOverride}>
                <LocationProfileProvider>
                  <Outlet />
                </LocationProfileProvider>
              </MainLayout>
            }
          >
            <Route
              path="locations/:locationIdentifier"
              element={<LocationProfile />}
            />
            <Route
              path="location/:citySlug/:locationIdentifier"
              element={<LocationProfile />}
            />
            <Route
              path="listing/:reviewId/:stateSlug/:citySlug/:locationIdentifier"
              element={<VideoProfile />}
            />
            <Route path="reviews/:reviewId" element={<VideoProfile />} />
          </Route>

          <Route
            path="*"
            element={
              <MainLayout isMobileOverride={isMobileOverride} showFooter={false}>
                <Outlet />
              </MainLayout>
            }
          >
            <Route path="users/:userSlug" element={<CreatorProfile />} />
            <Route path="city/:stateSlug/:citySlug" element={<CityProfile />} />
            <Route path="collections/:collectionId" element={<Watch />} />
            <Route
              path="users/:userIdentifier/collections/:userCollectionId"
              element={<Watch />}
            />
            <Route
              path="*"
              element={isMobileOverride ? (
                <Watch />
              ) : (
                <Marketing />
              )}
            />
          </Route>
        </Routes>
      </ErrorBoundary>
    </div>
  );
}

export default App;
