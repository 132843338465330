import * as React from "react";
import { isMobile } from "react-device-detect";
import Modal from "../../components/Modal/Modal";
import QRCode from "../../components/QRCode/QRCode";
import { useContext } from "react";
import { ModalContext } from "../../components/Modal/ModalContext";

const MarketingLayout = ({ children, isMobileOverride }) => {
  const { closeModal } = useContext(ModalContext);

  return (
    <main className="App">
      {!isMobile && !isMobileOverride && (
        <Modal content={<QRCode closeModal={closeModal} />} />
      )}
      {children}
    </main>
  );
};

export default MarketingLayout;
