import { getOneLinkWithClickSource } from "utils/getOneLink";
import closeIconSmall from "images/close-icon-small.svg";
import DownloadAppStore from "assets/icons/download-app-store.svg";
import DownloadGooglePlay from "assets/icons/download-google-play.svg";
import styles from "./QRCode.module.css";

const QRCode = ({ closeModal }) => {
  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.closeIconContainer} onClick={closeModal}>
          <img
            src={closeIconSmall}
            alt="Close"
            className={styles.closeIcon}
            loading="lazy"
          />
        </div>
        <div className={styles.header}>
          Download the <span style={{ color: "#FF3E99" }}>Atmosfy</span> app and
          discover the best spots
        </div>
      </div>
      <div className={styles.qrCodeContainer}>
        <div className="body__bold">
          Scan the QR code with your phone
        </div>
        <div className={styles.qrCode}>
          <img
            src="https://media.atmosfy.io/image/upload/v1692985582/marketing/QR_code.png"
            loading="lazy"
          />
        </div>
      </div>
      <div className={styles.downloadContainer}>
        <div className="body__bold">Or download from app stores</div>
        <div className={styles.downloadIconsContainer}>
          <a
            href={getOneLinkWithClickSource("qrcode-download-apple")}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={DownloadAppStore}
              alt="Download from App Store"
            />
          </a>
          <a
            href={getOneLinkWithClickSource("qrcode-download-google")}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={DownloadGooglePlay}
              alt="Download from Google Play"
            />
          </a>
        </div>
      </div>
    </div>
  );
};
export default QRCode;
