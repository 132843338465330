import * as React from "react";
import { isMobile as isMobileDevice } from "react-device-detect";
import atmosfyNewLogo from "../../images/atmosfy-new-logo.svg";
import { ModalContext } from "../Modal/ModalContext";
import { ATMOSFY_ONE_LINK } from "../../utils/constants";
import styles from "./Navigation.module.css";

const Navigation = React.forwardRef(({
  visibleOnMobile,
  compact
}, ref) => {
  const { openModal } = React.useContext(ModalContext);
  const isMobileWidth = window.innerWidth <= 768;

  const handleClickCta = React.useCallback(() => {
    if (isMobileDevice) {
      window.open(ATMOSFY_ONE_LINK);
      return;
    }

    openModal();
  }, [openModal]);

  const handleClickLogo = React.useCallback(() => {
    if (isMobileDevice && !visibleOnMobile) {
      return;
    }

    ref?.pageRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
    if (!ref) {
      window.location.href = window.location.origin;
    }
  }, [ref]);

  if (isMobileDevice && !visibleOnMobile) {
    return null;
  }

  if (compact) {
    return (
      <div className={styles.header}>
        <img
          src={atmosfyNewLogo}
          alt="Atmosfy logo"
          className={styles.logo}
          onClick={handleClickLogo}
          loading="lazy"
        />
      </div>
    );
  }

  return (
    <div className={styles.header}>
      <img
        src={atmosfyNewLogo}
        alt="Atmosfy logo"
        className={styles.logo}
        onClick={handleClickLogo}
        loading="lazy"
      />
      {!isMobileWidth && (
        <nav className={styles.nav}>
          <ul>
            <li
              onClick={() => {
                ref?.productRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "center"
                });
                if (!ref) {
                  window.location.href = window.location.origin;
                }
              }}
            >
              Product
            </li>
            <li
              onClick={() => {
                ref?.creatorsRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "center"
                });
                window.open("/creators");
              }}
            >
              Creators
            </li>
            <li onClick={() => window.location.href = "/biz"}>
              Biz Owners
            </li>
            <li onClick={() => window.location.href = "mailto:press@atmosfy.io"}>
              Press Kit
            </li>
          </ul>
        </nav>
      )}

      <button className={styles.buttonGetAtmosfy}>
        <div onClick={handleClickCta}>Get Atmosfy</div>
      </button>
    </div>
  );
});

export default Navigation;
