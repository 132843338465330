import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useFeatureFlagVariantKey } from "posthog-js/react";
import { CLAIM_BIZ_BANNER_COPY, POSTHOG_WAIT_TIME, VARIANT } from "utils/featureFlags";
import { formatClaimBusinessUrl } from "utils/business";
import ArrowRight from "images/white-arrow-back.svg"
import styles from "./ClaimBusinessBanner.module.css";

const ClaimBusinessBanner = ({ locationData }) => {
  const [millis, setMillis] = useState(0);

  const claimBizBannerCopyTest =
    useFeatureFlagVariantKey(CLAIM_BIZ_BANNER_COPY);

  const { pathname } = useLocation();
  const source = pathname === "/" ? "canonical" : pathname;

  useEffect(() => {
    if (millis > POSTHOG_WAIT_TIME) {
      return;
    }

    const interval = setInterval(() => {
      setMillis(millis + 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, [millis]);

  const isBannerCopyReady = useMemo(() => {
    return claimBizBannerCopyTest || millis > POSTHOG_WAIT_TIME;
  }, [claimBizBannerCopyTest, millis]);

  const { bannerText, bannerCTA } = useMemo(() => {
    if (claimBizBannerCopyTest === VARIANT) {
      return {
        bannerText: "Business owner? Get $250 now",
        bannerCTA: "Claim Now"
      }
    }

    return {
      bannerText: "Claim your businesses on Atmosfy today to connect with locals in your area",
      bannerCTA: "Claim Business"
    }
  }, [claimBizBannerCopyTest]);


  if (!isBannerCopyReady) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <span className={styles.text}>{bannerText}</span>
        <a
          className={styles.link}
          href={formatClaimBusinessUrl(source, locationData)}
          target="_blank"
          rel="noreferrer"
        >
          <span className={styles.linkText}>{bannerCTA}</span>
          <img src={ArrowRight} alt="Arrow Right" className={styles.icon}/>
        </a>
      </div>
    </div>
  )
}

export default ClaimBusinessBanner;