import React, { useContext } from "react";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import Modal from "components/Modal/Modal";
import QRCode from "components/QRCode/QRCode";
import { ModalContext } from "components/Modal/ModalContext";
import Navigation from "components/MarketingHeader/Navigation";
import ClaimBusinessBanner from "components/ClaimBusinessBanner";
import Footer from "components/Footer/Footer";
import StickyFooter from "components/StickyFooter";

const COMPACT_LAYOUT_ROUTES = [
  "/communityround"
];

const LandingPageLayout = ({ children }) => {
  const { closeModal } = useContext(ModalContext);
  const { pathname} = useLocation();

  const isCompactLayout = COMPACT_LAYOUT_ROUTES?.includes(pathname);


  return (
    <main className="App">
      <Navigation visibleOnMobile compact={isCompactLayout} />

      {!isMobile && !COMPACT_LAYOUT_ROUTES?.includes(pathname) && <ClaimBusinessBanner />}

      {children}

      {!isMobile && <Modal content={<QRCode closeModal={closeModal} />} />}

      {!isCompactLayout && <Footer />}

      {!isCompactLayout && <StickyFooter />}
    </main>
  );
};

export default LandingPageLayout;
